import React from 'react';
import styled, { keyframes } from 'styled-components';

const Container = styled.div`
  width: 100%;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
`;

const animation = keyframes`
  0%, 100% { opacity: 1 }
  50% { opacity: 0 }
`;

const SpinnerContainer = styled.div`
  margin: 0 auto;
  text-align: center;
`;

const SpinnerBounce3 = styled.div`
  width: 10px;
  height: 10px;
  background-color: #5863f8;
  border-radius: 100%;
  display: inline-block;
  animation: ${animation} 1.2s infinite ease-in-out both;
  margin-right: 10px;
`;

const SpinnerBounce1 = styled(SpinnerBounce3)`
  animation-delay: -0.32s;
`;

const SpinnerBounce2 = styled(SpinnerBounce3)`
  animation-delay: -0.16s;
`;

const Loader = ({ className, innerContainerStyle }) => (
  <Container className={className}>
    <SpinnerContainer style={innerContainerStyle}>
      <SpinnerBounce1 />
      <SpinnerBounce2 />
      <SpinnerBounce3 />
    </SpinnerContainer>
  </Container>
);

export default Loader;
