import { Box, Typography } from "@mui/material";
import styled from "styled-components";

export const Container = styled(Box)`
  && {
    position: relative;
    // overflow: hidden;
    z-index: 1;
    padding-top: 100px;
    max-width: 1100px;
  }
`;

export const Title = styled(Typography)`
  && {
    font-family: "proxima_nova_rgregular";
    font-style: normal;
    font-weight: 600;
    letter-spacing: 0.04em;
    color: #5863f8;
  }
`;

export const SubTitle = styled(Typography)`
  && {
    font-family: "Noto Sans JP";
    font-style: normal;
    font-weight: 700;
    letter-spacing: 0.04em;
    color: #ffffff;
    margin-bottom: 35px;
  }
`;

export const TextBody = styled(Typography)`
  && {
    // width: 50%;
    font-family: "Noto Sans JP";
    font-style: normal;
    font-weight: 400;
    line-height: 40px;
    letter-spacing: 0.04em;
    color: #ffffff;
  }
`;

export const AboutElipse = styled(Box)`
  && {
    position: absolute;
    background: linear-gradient(91.6deg, #5863f8 2.02%, #9437f2 97.29%);
    filter: blur(150px);
    z-index: 3;
  }
`;

export const Card = styled(Box)`
  && {
    width: 100%;
    max-width: 520px;
    background: #f9f2ff;
    border-radius: 10px;
    position: relative;
    z-index: 2;
    margin-top: 30px;
  }
`;

export const Tag = styled(Typography)`
  && {
    width: max-content;
    background: #ffffff;
    border-radius: 100px;
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 5px;
    position: relative;
    z-index: 3;

    font-family: "proxima_nova_rgregular";
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    color: #5863f8;
    opacity: 0.5;
  }
`;

export const Heading = styled(Typography)`
  && {
    font-family: "Noto Sans JP";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    letter-spacing: 0.04em;
    color: #222222;
    margin-bottom: 5px;
    position: relative;
    z-index: 3;
  }
`;

export const Point = styled(Typography)`
  && {
    font-family: "Noto Sans JP";
    font-style: normal;
    font-weight: 700;
    color: #222222;
    margin-left: 7px;
    position: relative;
    z-index: 3;
  }
`;

export const CardImage = styled(Box)`
  && {
    position: absolute;
    top: 0;
    right: 0;
    border-radius: 10px;
  }
`;
