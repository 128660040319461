import React from "react";
import styled from 'styled-components';
import { Box } from "@mui/material";
import companiesImage from '../../images/companies.webp';

const StyledBox = styled(Box)`
  background: url(${companiesImage}) repeat-x;
  background-size: cover;
  animation: animate-medias 100000s linear infinite;
  height: 76px;
  animation-iteration-count: infinite;

  @keyframes animate-medias {
    0% {
      background-position: 0 0;
    }
    100% {
      background-position: 10000000px 0;
    }
  }
`;

function Companies() {
  return (
    <StyledBox mt={9} />
  );
}

export default Companies;
