import { Box, ButtonBase, Typography } from "@mui/material";
import styled from "styled-components";

export const Container = styled(Box)`
  && {
    background: #fff;
    padding-top: 60px;
    padding-bottom: 60px;
  }
`;

export const Link = styled(ButtonBase)`
  && {
    font-family: "Noto Sans JP";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 23px;
    color: #222222;
  }
`;

export const Copyright = styled(Typography)`
  && {
    font-family: "Noto Sans JP";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #4f4f4f;
  }
`;
