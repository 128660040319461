import React, { Fragment, useEffect, useState } from "react";
import { Box, Grid, Stack } from "@mui/material";
import Navbar from "../header/navbar/navbar";
import {
  FormContainer,
  FormTitle,
  HeroElipse,
  HeroFormContainer,
  HeroText,
  OutlinedButton,
  ScrollContainer,
  ScrollText,
  SolidButton,
  TitleLine,
} from "./hero.components";
import CustomForm from "../contactsection/customform";

import "./hero.css";

import logo from "../../images/logo.webp";
import heroBg from "../../images/hero-bg.webp";
import heroMobileBg from "../../images/hero-mobile-bg.webp";

function Hero() {
  const [formSubmitted, setFormSubmitted] = useState(false);

  return (
    <Fragment>
      <HeroElipse
        sx={{
          width: { xs: 360, lg: 700 },
          height: { xs: 500, lg: 800 },
          left: { xs: "-93px", lg: "-257px" },
          top: { xs: "-104px", lg: "-326px" },
        }}
      />
      <Navbar />
      <Box sx={{ height: { xs: 60, lg: 90 } }} />
      <HeroContainer />
    </Fragment>
  );

  function HeroContainer() {
    return (
      <Grid container sx={{ position: "relative", lg: "auto", maxWidth: 1880 }}>
        <Grid item xs={12} lg={4.75} sx={{ pl: { xs: "20px", sm:"50px", lg: 10 }, mt: {lg: "auto"}, mb: {xs: "70px", sm: "260px", lg: "auto"}  }}>
          <Box sx={{ pt: {xs: "62px", sm: "146px", lg:"0" }}}>
            <Box
              component="img"
              src={logo}
              alt="logo"
              sx={{ width: {xs: "265px",sm: "347px"}}}
            />
            <HeroText
              sx={{
                fontSize: { xs: "26px",sm: "60px", lg: "35px", xl: "40px" },
                lineHeight: { xs: "42px",sm: "90px", lg: "64px" },
                mb: { xs: 7.5, lg: 4.5 },
              }}
            >
              クリエイターの大量制作に <br /> 特化した運用型 <br />
              クリエイティブチームを <br /> 作りませんか？
            </HeroText>
            <Stack
              direction={{ xs: "column", sm: "row" }}
              justifyContent="flex-start"
              alignItems="center"
              spacing={{ xs: 2.5, lg: 1.5, xl: 3.5 }}
            >
              <SolidButton
                onClick={() => {
                  const anchor = document.querySelector("#contact");
                  anchor.scrollIntoView({
                    behavior: "smooth",
                    block: "center",
                  });
                }}
                sx={{ 
                    fontSize: "16px",
                    width: {xs: "220px", sm: "44%", lg: "220px"},
                    height: "50px",
                   }}
              >
                資料請求
              </SolidButton>
              <OutlinedButton
                component="a"
                href="https://meetings.hubspot.com/koichi-kitamura"
                target="_blank"
                sx={{ 
                    fontSize: "16px",
                    width: {xs: "220px", sm: "44%", lg: "220px"},
                    height: "50px",
                  }}
              >
                担当者に話を聞く
              </OutlinedButton>
            </Stack>
          </Box>
          <ScrollContainer
            sx={{ left: { xs: 0, lg: "40px" }, bottom: { lg: "45px" }, mt: { sm: "100px" } }}
          >
            <ScrollText>SCROLL DOWN</ScrollText>
            <Box className="scrolldown1" />
          </ScrollContainer>
        </Grid>
        <Grid item xs={0} lg={7.25}>
          <HeroFormContainer
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{
              px: { xs: 0, lg: 2.5 },
              py: { xs: 3, lg: 0 },
              borderRadius: { lg: "20px 0px 0px 20px", sm: "40px" },
              height: { xs: 550, sm: 600, lg: "750px", xl: 850 },
            }}
          >
            <Box
              component="img"
              src={heroMobileBg}
              alt=""
              sx={{
                width: { xs: "100%", sm: "65%", md: '50%' },
                mx: "auto",
                display: { xs: "block", lg: "none" },
              }}
            />
            <Box
              component="img"
              src={heroBg}
              alt=""
              sx={{
                width: { lg: "600px", xl: "666" },
                height: {xl: "745px"},
                display: { xs: "none", lg: "block" },
                margin: {md: "auto", lg: 0},
              }}
            />
            <FormContainer
              sx={{
                display: { xs: "none", lg: "block" },
                width: { xs: 425, lg:500, xl: 520 },
                padding: {sm: "60px 40px", md: "60px 40px", lg: "40px", xl: "52px 60px 60px"}
              }}
            >
              <FormTitle>資料請求</FormTitle>
              <TitleLine />
              <CustomForm
                formSubmitted={formSubmitted}
                setFormSubmitted={setFormSubmitted}
              />
            </FormContainer>
          </HeroFormContainer>
        </Grid>
      </Grid>
    );
  }
}

export default Hero;
