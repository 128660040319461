import React from "react";
import { Box, Grid, Stack } from "@mui/material";
import {
  AboutElipse,
  Card,
  CardImage,
  Container,
  Heading,
  Point,
  SubTitle,
  Tag,
  TextBody,
  Title,
} from "./about.components";
import { CardContent } from "./content";

import aboutImage from "../../images/about-img.webp";
import aboutCircleImage from "../../images/about-circle.webp";
import tick from "../../images/tick-sign.svg";

function About() {
  return (
    <Container
      sx={{
        mx: "auto",
        px: { xs: 2.5, sm: "50px", lg: 0 },
        pb: { xs: "120px", pb: 0 },
      }}
    >
      <Title sx={{ fontSize: { xs: "40px", sm: "80px", lg: "60px" } }}>ABOUT</Title>
      <div id="about" />
      <Grid container>
        <Grid item lg={6.25}>
          <SubTitle sx={{ fontSize: { xs: "20px", sm: "32px", lg: "24px" } }}>- foriio Labとは？</SubTitle>
          <TextBody sx={{ fontSize: { xs: "14px", sm: "20px", lg: "16px" } }}>
            foriio
            Labは、御社が抱えているクリエイティブ制作に関する業務を専任のディレクターと固定のクリエイターチームで実現します。多人数での制作と専任ディレクターが全体を見る事で、制作のスピードアップ＋デザインの幅を広げ、御社のクリエイティブ自動化のスピードを最速で実現可能です。
          </TextBody>
          <Stack direction="row" justifyContent="center" mt={4}>
            <Box
              component="img"
              src={aboutCircleImage}
              alt=""
              sx={{ width: { xs: "95%", lg: 475 } }}
            />
          </Stack>
        </Grid>
        <Grid
          item
          md={5.75}
          sx={{ display: { xs: "none", lg: "block", position: "relative" } }}
        >
          <Box
            component="img"
            src={aboutImage}
            alt=""
            sx={{ width: "100%", position: "relative", zIndex: 4 }}
          />
          <AboutElipse
            sx={{
              width: { xs: 360, lg: 135 },
              height: { xs: 500, lg: 450 },
              left: { xs: "-93px", lg: "180px" },
              top: { xs: "-104px", lg: "50px" },
              opacity: 0.5,
            }}
          />
        </Grid>
      </Grid>
      <Stack
        direction={{ xs: "column", lg: "row" }}
        alignItems="center"
        spacing={10}
        sx={{
          width: "100%",
          mt: '10px',
          position: "relative",
          zIndex: 2,
        }}
      >
        {CardContent.map((data, index) => {
          return (
            <Card key={data.tag}
            sx={{
              padding: { xs: '20px 20px', lg: '20px 40px'}
            }}
            >
              <Tag>{data.tag}</Tag>
              <Heading>{data.title}</Heading>
              {data.points.map((point, indx) => {
                return (
                  <Stack
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    mt={1.2}
                    key={point}
                  >
                    <Box component="img" src={tick} alt="" />
                    <Point sx={{ fontSize: { xs: "14px", lg: "15px" } }}>
                      {point}
                    </Point>
                  </Stack>
                );
              })}
              <CardImage
                component="img"
                src={data.image}
                alt=""
                sx={{ width: { xs: 100, sm: 150, lg: 200 } }}
              />
            </Card>
          );
        })}
      </Stack>
      <AboutElipse
        sx={{
          width: { xs: 300, lg: 200 },
          height: { xs: 500, lg: 450 },
          left: { xs: "65px", lg: "250px" },
          top: { lg: "925px" },
          opacity: { xs: 0.3, lg: 0.4 },
        }}
      />
    </Container>
  );
}

export default About;
