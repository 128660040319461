import React, { Fragment, useState, useEffect } from "react";
import {
  AppBar,
  Toolbar,
  CssBaseline,
  useScrollTrigger,
  Stack,
  Box,
} from "@mui/material";
import { NavItems } from "../header.content";
import { NavItem, SolidButton } from "./navbar.components";
import logo from "../../../images/logo.webp";
import NavMenu from "../navmenu/menu";

function ElevationScroll(props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

function Navbar(props) {
  var [positionExceeded, setPositionExceeded] = useState(false);

  const handleScroll = () => {
    const position = window.pageYOffset;
    setPositionExceeded(position > 15);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <Fragment>
      <CssBaseline />
      <ElevationScroll {...props}>
        <AppBar
          sx={{
            background: positionExceeded ? "#222222" : "transparent",
          }}
        >
          <Toolbar
            sx={{
              height: { xs: 60, md: 90 },
              background: positionExceeded ? "#222222" : "transparent",
            }}
          >
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{ width: "100%", display: { xs: "none", lg: "flex" } }}
            >
              <Box
                component="img"
                src={logo}
                alt=""
                sx={{ width: 160}}
              />

              <Stack
                direction="row"
                justifyContent="space-between"
                spacing={7.5}
              >
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  spacing={5}
                >
                  {NavItems.map((item, index) => {
                    return index !== 5 ? (
                      <NavItem
                        key={item.sectionId}
                        onClick={() => {
                          const anchor = document.querySelector(
                            `${item.sectionId}`
                          );
                          anchor.scrollIntoView({
                            behavior: "smooth",
                            block: "center",
                          });
                        }}
                      >
                        {item.japanese}
                      </NavItem>
                    ) : (
                      ""
                    );
                  })}
                </Stack>
                <SolidButton
                  onClick={() => {
                    const anchor = document.querySelector(
                      `${NavItems[5].sectionId}`
                    );
                    anchor.scrollIntoView({
                      behavior: "smooth",
                      block: "center",
                    });
                  }}
                >
                  {NavItems[5].japanese}
                </SolidButton>
              </Stack>
            </Stack>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{
                width: "100%",
                py: 2.75,
                display: { xs: "flex", lg: "none" },
              }}
            >
              <Box
                component="img"
                src={logo}
                alt=""
                sx={{ width: {xs: 95, sm: 160} }}
              />
              <NavMenu />
            </Stack>
          </Toolbar>
        </AppBar>
      </ElevationScroll>
    </Fragment>
  );
}

export default Navbar;
