import React from "react";
import { Avatar, Box, Grid, IconButton, Modal, Stack } from "@mui/material";
import { Forte, Name, Description, Title, Heading } from "./modal.components";
import Slider from "react-slick";

import closeIcon from "../../../images/close-icon.svg";
import pawIcon from "../../../images/paw-icon.svg";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: "100%", md: 800 },
  height: { xs: "100%", md: "auto" },
  bgcolor: "background.paper",
  padding: { xs: "8px 20px", md: "8px 40px"},
  px: 5,
  py: 1,
  overflow: { xs: "auto", md: "none" },
};

function ExampleModal({ openModal, setOpenModal, data }) {
  return (
    <Modal
      open={openModal}
      onClose={() => setOpenModal(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Stack direction="row" justifyContent="flex-end" mb={1.5}>
          <IconButton
            sx={{ height: 45, width: 45 }}
            onClick={() => setOpenModal(false)}
          >
            <Box
              component="img"
              src={closeIcon}
              alt=""
              sx={{ width: "24px" }}
            />
          </IconButton>
        </Stack>
        <Stack
          direction={{ xs: "column-reverse", md: "row" }}
          justifyContent="center"
          spacing={3.5}
        >
          <Box
            sx={{
              maxHeight: { md: 630, xl: 765 },
              textAlign: 'center',
              overflow: { md: "auto" },
            }}
          >
            {data.images?.map((workImage, indx) => {
              return (
                <Box
                  key={workImage.id}
                  component="img"
                  src={workImage.urls?.detail}
                  sx={{
                    width: { md: 560, xs: '100%' },
                    background: "#e7eded",
                    mb: 1.5,
                  }}
                />
              );
            })}
            {!data.images?.length &&
              <Box
                component="img"
                src={data.thumbnail}
                sx={{
                  width: { md: 620, xs: '100%' },
                  background: "#e7eded",
                  mb: 1.5,
                }}
              />
            }
          </Box>
          {/* <Box sx={{ width: { md: 350 } }}>
            <Title
              sx={{
                mb: { xs: 3.5, md: 1.75 },
                mx: "auto",
                width: { xs: "80%", md: "100%" },
                fontSize: { xs: "31px", md: "24px" },
                textAlign: { xs: "center", md: "left" },
              }}
            >
              {data.title}
            </Title>
            <Box mb={4.5} sx={{ display: { xs: "none", md: "block" } }}>
              <Description>{data.description}</Description>
            </Box>

            <Box sx={{ display: { xs: "none", md: "block" } }}>
              <Stack direction="row" alignItems="center" spacing={1} mb={2.25}>
                <Box component="img" src={pawIcon} alt="" />
                <Heading>クレジット</Heading>
              </Stack>
              {data.credits.map((eachCredit, index) => {
                return (
                  <Stack
                    direction="row"
                    alignItems="center"
                    spacing={1.25}
                    mb={2}
                  >
                    <Avatar
                      src={eachCredit.user.avatar.thumb}
                      alt=""
                      sx={{ width: 40, height: 40 }}
                    />
                    <Box>
                      <Name>{eachCredit.user.name}</Name>
                      <Grid container>
                        {eachCredit.creative_roles.map((role, indx) => {
                          return <Forte>{role.name}</Forte>;
                        })}
                      </Grid>
                    </Box>
                  </Stack>
                );
              })}
            </Box>
            <Box
              sx={{
                display: { xs: "block", md: "none" },
                py: 3.5,
                borderTop: "1px solid #F2F2F2",
                borderBottom: "1px solid #F2F2F2",
              }}
            >
              {CreatorsCarousel()}
            </Box>
          </Box> */}
        </Stack>
      </Box>
    </Modal>
  );

  function CreatorsCarousel() {
    const settings = {
      infinite: false,
      speed: 500,
      slidesToShow: 1.3,
      slidesToScroll: 1,
    };

    return (
      <Slider {...settings}>
        {data.credits.map((eachCredit, index) => {
          return (
            <Stack
              direction="row"
              alignItems="center"
              spacing={1.25}
              sx={{ display: "flex !important" }}
            >
              <Avatar
                src={eachCredit.user.avatar.thumb}
                alt=""
                sx={{ width: 60, height: 60 }}
              />
              <Box>
                <Forte>{eachCredit.creative_roles[0].name}</Forte>
                <Name>{eachCredit.user.name}</Name>
              </Box>
            </Stack>
          );
        })}
      </Slider>
    );
  }
}
export default ExampleModal;
