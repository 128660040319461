import React from "react";
import { Box, Stack } from "@mui/material";
import {
  Answer,
  Icon,
  Card,
  Container,
  Question,
  SubTitle,
  Title,
  ShowMoreButton,
} from "./q&a.components";
import { QandAContent } from "./content";

function QAndA() {
  return (
    <Box sx={{ background: "#f9f2ff" }}>
      <Container
        sx={{ pl: { xs: 2.5, sm: "50px", lg: 0 }, mx: "auto", pr: { xs: 2.5, sm: "50px", lg: 0 } }}
      >
        <Title sx={{ fontSize: { xs: "40px", sm:"80px" ,lg: "60px" } }}>Q&A</Title>
        <SubTitle sx={{ fontSize: { xs: "20px", sm: "32px", lg: "24px" } }}>- よくある質問</SubTitle>
        <div id="qna" />
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="flex-start"
          spacing={2.5}
          sx={{ width: { lg: "80%" }, mx: "auto" }}
        >
          {QandAContent.map((data, index) => {
            return (
              <Card key={data.question} sx={{ p: { xs: 2.5, lg: 5 } }}>
                <Stack
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  spacing={2}
                  sx={{ mb: { xs: 2.5, lg: 3.75 } }}
                >
                  <Icon bgcolor="#5863f8" txtcolor="#ffffff">
                    Q
                  </Icon>
                  <Question>{data.question}</Question>
                </Stack>
                <Stack
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  spacing={1.75}
                >
                  <Icon bgcolor="#f9f2ff" txtcolor="#5863f8">
                    A
                  </Icon>
                  <Answer>{data.answer}</Answer>
                </Stack>
              </Card>
            );
          })}
        </Stack>
        <Stack direction="row" justifyContent="center" sx={{ mt: 7 }}>
          <ShowMoreButton
            component="a"
            href="https://knowledge.foriio.com/knowledge/foriio-lab"
            target="_blank"
          >
            もっと質問を見る
          </ShowMoreButton>
        </Stack>
      </Container>
    </Box>
  );
}

export default QAndA;
