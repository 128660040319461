import { Box, ButtonBase, Typography } from "@mui/material";
import styled from "styled-components";

export const Container = styled(Box)`
  && {
    padding-top: 40px;
    padding-bottom: 130px;
    max-width: 1100px;
  }
`;

export const Title = styled(Typography)`
  && {
    font-family: "proxima_nova_rgregular";
    font-style: normal;
    font-weight: 600;
    letter-spacing: 0.04em;
    color: #5863f8;
  }
`;

export const SubTitle = styled(Typography)`
  && {
    font-family: "Noto Sans JP";
    font-style: normal;
    font-weight: 700;
    letter-spacing: 0.04em;
    color: #222222;
    margin-bottom: 45px;
  }
`;

export const Card = styled(Box)`
  && {
    background: #ffffff;
    border-radius: 20px;
    box-shadow: 0px 8px 40px rgba(64, 68, 125, 0.15);
  }
`;

export const Question = styled(Typography)`
  && {
    font-family: "Noto Sans JP";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 0.04em;
    color: #222222;
  }
`;

export const Answer = styled(Typography)`
  && {
    font-family: "Noto Sans JP";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: 0.04em;
    color: #222222;
  }
`;

export const Icon = styled(ButtonBase)`
  && {
    background: ${(props) => props.bgcolor};
    border-radius: 50%;
    width: 30px;
    min-width: 30px;
    height: 30px;
    min-height: 30px;

    font-family: "proxima_nova_rgregular";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 30px;
    letter-spacing: 0.04em;
    color: ${(props) => props.txtcolor};
  }
`;

export const ShowMoreButton = styled(ButtonBase)`
  && {
    width: 230px;
    height: 50px;
    border: 1px solid #5863f8;
    border-radius: 100px;

    font-family: "Noto Sans JP";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    text-align: center;
    letter-spacing: 0.05em;
    color: #5863f8;

    &:hover {
      color: #fff;
      background: #5863f8;
    }
  }
`;
