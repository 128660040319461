import { Box, ButtonBase, Typography } from "@mui/material";
import styled from "styled-components";
import { styled as muiStyled } from "@mui/material/styles";

export const Container = styled(Box)`
  && {
    padding-top: 40px;
    padding-bottom: 100px;
    max-width: 1120px;
  }
`;

export const Title = styled(Typography)`
  && {
    font-family: "proxima_nova_rgregular";
    font-style: normal;
    font-weight: 600;
    letter-spacing: 0.04em;
    color: #5863f8;
  }
`;

export const SubTitle = styled(Typography)`
  && {
    font-family: "Noto Sans JP";
    font-style: normal;
    font-weight: 700;
    letter-spacing: 0.04em;
    color: #ffffff;
    margin-bottom: 45px;
  }
`;

export const CategoryButton = styled(ButtonBase)`
  && {
    width: 154px;
    height: 40px;
    box-shadow: 2px 3px 30px rgba(88, 99, 248, 0.5);
    border-radius: 100px;

    font-family: "Noto Sans JP";
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 100%;

    &:hover {
      color: #5863f8cc;
      background: #dfd2ea;
    }
    &:focus {
      color: #f9f2ff;
      background: #5863f8;
    }
  }
`;

export const CategoryText = styled(Typography)`
  && {
    font-family: "Noto Sans JP";
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
    color: #ffffff;
  }
`;

export const ShowMoreButton = styled(ButtonBase)`
  && {
    width: 230px;
    height: 50px;
    border: 1px solid #ffffff;
    border-radius: 100px;

    font-family: "Noto Sans JP";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    text-align: center;
    letter-spacing: 0.05em;
    color: #ffffff;
  }
`;

export const ImageGradiant = styled.div`
  && {
    position: absolute;
    width: 100%;
    height: 100%;
    inset: 0px;
    background: linear-gradient(rgba(0, 0, 0, 0) 51.56%, rgba(0, 0, 0, 0.7) 100%);
    border-radius: 5px;
  }
`;

export const CardGrid = muiStyled(Box)(({ theme }) => ({
  display: "grid",
  "grid-column-gap": "20px",
  "grid-row-gap": "20px",

  [theme.breakpoints.up("xs")]: {
    "grid-template-columns": "repeat(2, 1fr)",
  },
  [theme.breakpoints.up("sm")]: {
    "grid-template-columns": "repeat(3, 1fr)",
  },
  [theme.breakpoints.up("lg")]: {
     "grid-template-columns": "repeat(4, 1fr)",
  },
}));