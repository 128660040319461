import { Box, ButtonBase, Stack, TextField, Typography } from "@mui/material";
import styled from "styled-components";

export const HeroElipse = styled(Box)`
  && {
    position: absolute;
    background: linear-gradient(91.6deg, #5863f8 2.02%, #9437f2 97.29%);
    opacity: 0.3;
    filter: blur(150px);
  }
`;

export const HeroText = styled(Typography)`
  && {
    font-family: "Noto Sans JP";
    font-style: normal;
    font-weight: 700;
    letter-spacing: 0.04em;
    color: #ffffff;
    margin-top: 30px;
  }
`;

export const SolidButton = styled(ButtonBase)`
  && {
    background: linear-gradient(91.6deg, #5863f8 2.02%, #9437f2 97.29%);
    box-shadow: 2px 3px 30px rgba(88, 99, 248, 0.5);
    border-radius: 100px !important;

    font-family: "Noto Sans JP";
    font-style: normal;
    font-weight: 700;
    line-height: 0%;
    letter-spacing: 0.05em;
    color: #ffffff;

    &:hover {
      color: #5863f8;
      background: #fff;
      box-shadow: none;
    }
  }
`;

export const OutlinedButton = styled(ButtonBase)`
  && {
    border: 1px solid #ffffff;
    border-radius: 100px !important;

    font-family: "Noto Sans JP";
    font-style: normal;
    font-weight: 700;
    line-height: 0%;
    letter-spacing: 0.05em;
    color: #ffffff;

    &:hover {
      color: #5863f8;
      background: #fff;
    }
  }
`;

export const ScrollContainer = styled(Box)`
  && {
    width: 100px;
    height: 17px;
    position: absolute;
  }
`;

export const ScrollText = styled(Box)`
  && {
    transform: rotate(90deg);
    font-family: "proxima_nova_rgregular";
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    letter-spacing: 0.04em;
    color: #ffffff;
  }
`;

export const HeroFormContainer = styled(Stack)`
  && {
    width: 100%;
    background: #f9f2ff;
  }
`;

export const FormContainer = styled(Box)`
  && {
    position: absolute;
    right: 26px;
    margin: 80px 0;
    filter: drop-shadow(0px 8px 40px rgba(64, 68, 125, 0.15));
    background: #ffffff;
    border-radius: 20px;
  }
`;

export const FormTitle = styled(Box)`
  && {
    height: 45px;
    font-family: "Noto Sans JP";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    letter-spacing: 0.07em;
    color: #222222;
    text-align: center;
  }
`;

export const TitleLine = styled(Box)`
  && {
    height: 1.25px;
    width: 60px;
    background: linear-gradient(91.6deg, #5863f8 2.02%, #9437f2 97.29%);
    margin: auto;
    margin-bottom: 10px;
  }
`;
