import { Typography } from "@mui/material";
import styled from "styled-components";

export const Title = styled(Typography)`
  && {
    font-family: "proxima_nova_rgregular";
    font-style: normal;
    font-weight: 700;
    line-height: 29px;
    color: #222222;
    overflow-wrap: break-word;
  }
`;

export const Description = styled(Typography)`
  && {
    font-family: "Noto Sans JP";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 173.76%;
    color: #828282;
    overflow-wrap: break-word;
  }
`;

export const Heading = styled(Typography)`
  && {
    font-family: "proxima_nova_rgregular";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.03em;
    color: #222222;
  }
`;

export const Name = styled(Typography)`
  && {
    font-family: "proxima_nova_rgregular";
    font-size: 16px;
    font-weight: 700;
    line-height: 1.5;
    letter-spacing: 0.05em;
    color: #222222;
    margin-left: 3px;
    margin-bottom: 5px;
  }
`;

export const Forte = styled(Typography)`
  && {
    width: max-content;
    background: #f2f2f2;
    border-radius: 5px;
    padding: 4px 8px;
    margin: 3px;

    font-family: "proxima_nova_rgregular";
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.05em;
    color: #4f4f4f;
  }
`;
