import React from "react";
import { Box, Stack } from "@mui/material";
import { Container, Copyright, Link } from "./footer.components";

import darkLogo from "../../images/logo-dark.webp";

function Footer() {
  const links = [
    {
      url: "https://www.foriio.com/privacy-policy",
      text: "プライバシーポリシー",
    },
    { url: "https://www.foriio.com/terms-of-use", text: "利用規約" },
    { url: "https://www.company.foriio.com", text: "運営会社" },
  ];

  return (
    <Container>
      <Stack
        direction={{ xs: "column", md: "row" }}
        justifyContent="center"
        alignItems="center"
        spacing={{ xs: 2, md: 4 }}
        sx={{ mb: { xs: 8, md: 5 } }}
      >
        {links.map((link, index) => {
          return (
            <Link key={link.url} component="a" href={link.url} target="_blank">
              {link.text}
            </Link>
          );
        })}
      </Stack>
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={{ xs: 2.5, md: 1 }}
      >
        <Box
          component="img"
          src={darkLogo}
          alt=""
          sx={{ width: 125, height: 40 }}
        />
        <Copyright>©foriio inc. All rights reserved.</Copyright>
      </Stack>
    </Container>
  );
}

export default Footer;
