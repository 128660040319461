export const QandAContent = [
  {
    question: "制作の内容にはどんなものがありますか？",
    answer:
      "Webデザイン、バナー、動画、編集、ライティングを中心に様々なご依頼を承っております。まずはご相談のお打ち合わせからご要望をお伝えください。",
  },
  {
    question: "どのくらいの期間で開始できますか？",
    answer:
      "最短で1週間からクリエイター/ディレクターの担当者をアサイン・業務説明を行い、業務の開始を行っています。が、遅くとも2週間あれば問題なく対応ができます。",
  },
  {
    question: "契約の期間に縛りはありますか？",
    answer:
      "金額自体は、「1件●●円」というものを決めた上で実施する形になるので、契約の期間に関して特段の縛りはありません。但し、同じチームで稼働を行う都合上、期間が長くなればなるほど効率化が出来る為、スピーディかつ高品質な制作が可能です。",
  },
  {
    question: "クリエイターを選ぶ事はできますか？",
    answer:
      "クリエイターは、ポートフォリオプラットフォームである弊社運営の「foriio」サービスに登録しているクリエイターからお選びいただく事ができます。",
  },
];
