import React, { useEffect, useState } from "react";
import { Box, Stack } from "@mui/material";
import {
  Card,
  Container,
  SubTitle,
  Title,
  Heading,
  Tab,
  FormTitle,
  TitleLine,
} from "./contact.components";
import CustomForm from "./customform";

function Contact() {
  const [tabIndex, setTabIndex] = useState(1);
  const [onHubspotForm, setOnHubspotForm] = useState(true);
  const [formSubmitted, setFormSubmitted] = useState(false);

  useEffect(() => {
    if (!onHubspotForm) return;

    const script = document.createElement("script");
    script.src = "https://js.hsforms.net/forms/embed/v2.js";
    document.body.appendChild(script);

    script.addEventListener("load", () => {
      // @TS-ignore
      if (window.hbspt) {
        // @TS-ignore
        window.hbspt.forms.create({
          region: "na1",
          portalId: "21032329",
          formId: "781e0508-4934-417d-99a9-249724ce909f",
          target: "#hubspotForm",
        });
      }
    });
  }, [onHubspotForm]);

  return (
    <div>
    <Container sx={{ pl: { xs: 0, sm: "50px", lg: 0 }, mx: "auto", pr: { xs: 0, sm: "50px", lg: 0 } }}>
        <Box sx={{
          marginTop: '40px',
          px: {xs: 2.5, sm: 0}
        }}>
         <Title sx={{fontSize:{xs: "40px", sm:"80px" ,lg: "60px"}}}>CONTACT</Title>
          <SubTitle sx={{ fontSize: { xs: "20px", sm: "32px", lg: "24px" } }}>- お問い合わせ・資料請求</SubTitle>
        </Box>
      <div id="contact" />
      <Card
        sx={{
          width: { lg: "60%" },
          padding: { xs: "40px 20px 60px 20px", md: "60px 80px"},
          borderRadius: {xs: '0px', sm: '20px'},
        }}
      >
        {formSubmitted ? (
          <Box>
            <FormTitle>資料請求</FormTitle>
            <TitleLine />
          </Box>
        ) : (
          <Box>
            <Heading
            sx= {{ mb: {xs: '40px', md: '60px'} }}
            >
              お問い合わせ、資料請求は以下のフォームよりお送りください。
              内容確認後、担当より通常1〜3営業日以内にご連絡いたします。
            </Heading>
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              sx= {{ mb: {xs: '40px', lg: '60px'} }}
            >
              <Tab
                onClick={() => {
                  setTabIndex(1);
                  setOnHubspotForm(true);
                }}
                sx={{
                  color: tabIndex === 1 ? "#5863F8" : "#bdbdbd",
                  borderBottomColor: tabIndex === 1 ? "#5863F8" : "#bdbdbd",
                }}
              >
                お問い合わせ
              </Tab>
              <Tab
                onClick={() => {
                  setTabIndex(2);
                  setOnHubspotForm(false);
                }}
                sx={{
                  color: tabIndex === 2 ? "#5863F8" : "#bdbdbd",
                  borderBottomColor: tabIndex === 2 ? "#5863F8" : "#bdbdbd",
                }}
              >
                資料請求
              </Tab>
            </Stack>
          </Box>
        )}
        {tabIndex === 1 ? (
          <Box id="hubspotForm" />
        ) : tabIndex === 2 ? (
          <CustomForm
            formSubmitted={formSubmitted}
            setFormSubmitted={setFormSubmitted}
          />
        ) : (
          ""
        )}
      </Card>
    </Container>
    </div>
  );
}

export default Contact;
