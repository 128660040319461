import Example1 from "../../images/example-1.webp";
import Example2 from "../../images/example-2.webp";
import Example3 from "../../images/example-3.webp";

export const Examples = [
  {
    image: Example1,
    title: "世界で愛される日本のものづくり、foriioと創造する一歩先行くプロ…",
    description:
      "クリエイターとのコミュニケーションコストを大きく削減することができ、期待以上のデザイン案を受け取れました。私は自分自身がデザインできることもあり、デザインを発注する際に指示書をかなり細かくつくっています。提出されるデザインが、自分の指示書の内容を超えてくるか？を見ているため…",
    url: "https://ownedmedia.foriio.com/1545/",
  },
  {
    image: Example2,
    title: "時代を先駆ける企業のクリエイター戦略、foriioという選択",
    description:
      "「事業開発」の中で、NFTを使ったアーティストのオリジナル音源データの販売ができる「.mura（ドットミューラ）」の開発にあたりクリエイターを探していたので、話を聞いてみました。そこで候補に挙げていただいたクリエイターの実績を見て、相性が合うと感じました。",
    url: "https://ownedmedia.foriio.com/1566/",
  },
  {
    image: Example3,
    title: "エンタメ業界のパイオニア企業がforiioを選ぶ理由",
    description:
      "主務としてデジタルマーケティング部署におりますので、「インフルエンサーのコンテンツマーケティングがしたい」と言う相談をさせていただきました。クリエイターのフォローアップに入っていただくという形でした。『Be』ではWebページの改修などをforiioにお願いしています。『VEE』とい…",
    url: "https://ownedmedia.foriio.com/1555/",
  },
];
