import React from "react";
import { Box, Stack } from "@mui/material";
import { Container, ImageContainer, SubTitle, Title } from "./flow.components";

import step1Image from "../../images/workflow-img-1.webp";
import step2Image from "../../images/workflow-img-2.webp";
import step2MobileImage from "../../images/workflow-img-2-mobile.webp";
import rightArrow from "../../images/right-arrow.svg";
import downArrow from "../../images/down-arrow.svg";

function Workflow() {
  return (
    <Box sx={{ background: "#f9f2ff", position: "relative", zIndex: 3 }}>
      <Container
        sx={{ pl: { xs: 2.5, sm: "50px", lg: 0 }, mx: "auto", pr: { xs: 2.5, sm: "50px", lg: 0 } }}
      >
        <Title sx={{ fontSize: { xs: "40px", sm:"80px" ,lg: "60px" } }}>WORK FLOW</Title>
        <SubTitle sx={{ fontSize: { xs: "20px", sm: "32px", lg: "24px" } }}>- 新しいワークフロー</SubTitle>
        <div id="workflow" />
        <Stack direction={{ xs: "column", lg: "row" }}>
          <ImageContainer
            sx={{
              width: { xs: "100%", lg: 400 },
              height: { xs: "100%", lg: 745 },
              px: { xs: 2.5, lg: 5 },
              py: 7,
            }}
          >
            <Box
              component="img"
              src={step1Image}
              alt=""
              sx={{ width: "100%" }}
            />
          </ImageContainer>
          <Box
            component="img"
            src={rightArrow}
            alt=""
            mx={5}
            sx={{ display: { xs: "none", lg: "block" } }}
          />
          <Box
            component="img"
            src={downArrow}
            alt=""
            mx={5}
            sx={{
              display: { xs: "block", lg: "none" },
              width: 40,
              mx: "auto",
              my: 5,
            }}
          />
          <ImageContainer
            sx={{
              width: { xs: "100%", lg: 600 },
              height: { xs: "100%", lg: 745 },
              px: 2.5,
              py: 7,
            }}
          >
            <Box
              component="img"
              src={step2Image}
              alt=""
              sx={{
                width: "90%",
                mx: "auto",
                display: { xs: "none", lg: "block" },
              }}
            />
            <Box
              component="img"
              src={step2MobileImage}
              alt=""
              sx={{ width: "100%", display: { xs: "block", lg: "none" } }}
            />
          </ImageContainer>
        </Stack>
      </Container>
    </Box>
  );
}

export default Workflow;
