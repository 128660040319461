import { Box, ButtonBase, Typography } from "@mui/material";
import styled from "styled-components";

export const Container = styled(Box)`
  && {
    padding-top: 40px;
    padding-bottom: 70px;
    max-width: 1100px;
  }
`;

export const Title = styled(Typography)`
  && {
    font-family: "proxima_nova_rgregular";
    font-style: normal;
    font-weight: 600;
    letter-spacing: 0.04em;
    color: #5863f8;
  }
`;

export const SubTitle = styled(Typography)`
  && {
    font-family: "Noto Sans JP";
    font-style: normal;
    font-weight: 700;
    letter-spacing: 0.04em;
    color: #ffffff;
    margin-bottom: 45px;
  }
`;

export const Heading = styled(Typography)`
  && {
    font-family: "Noto Sans JP";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 0.04em;
    color: #ffffff;
    margin-top: 25px;
    margin-bottom: 30px;
  }
`;

export const Description = styled(Typography)`
  && {
    font-family: "Noto Sans JP";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.04em;
    color: #ffffff;
    margin-bottom: 40px;
  }
`;

export const ShowMoreButton = styled(ButtonBase)`
  && {
    border: 1px solid #ffffff;
    border-radius: 100px;

    font-family: "Noto Sans JP";
    font-style: normal;
    font-weight: 700;
    text-align: center;
    letter-spacing: 0.05em;
    color: #ffffff;

    &:hover {
      color: #5863f8;
      background: #fff;
    }
  }
`;
