import React, { Fragment, useState } from "react";
import { Box, ButtonBase, Stack } from "@mui/material";
import {
  EnglishNavItem,
  JapaneseNavItem,
  MenuContainer,
  MenuText,
} from "./menu.components";
import { NavItems } from "../header.content";

import logo from "../../../images/logo.webp";
import menuIcon from "../../../images/menu-icon.webp";
import closeIcon from "../../../images/close-icon-light.svg";

function NavMenu() {
  const [openMenu, setOpenMenu] = useState(false);

  const handleMenuDisplay = (sectionId) => {
    setOpenMenu(!openMenu);

    const anchor = document.querySelector(sectionId);
    anchor.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  };

  return (
    <Fragment>
      {openMenu ? (
        <SideMenubar />
      ) : (
        <ButtonBase sx={{ color: "#fff" }} onClick={handleMenuDisplay}>
          <MenuText sx={{ marginRight : '13px' }}>MENU</MenuText>
          <Box component="img" src={menuIcon} alt="" sx={{ width: 40 }} />
        </ButtonBase>
      )}
    </Fragment>
  );

  function SideMenubar() {
    return (
      <MenuContainer>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ width: "100%", px: 3, py: 2.75 }}
        >
          <Box
            component="img"
            src={logo}
            alt=""
            sx={{ width: {xs: 95, sm: 160} }}
          />
          <ButtonBase onClick={handleMenuDisplay}>
            <MenuText>CLOSE</MenuText>
            <Box component="img" src={closeIcon} alt="" sx={{ width: 24 }} />
          </ButtonBase>
        </Stack>
        <Stack
          direction="column"
          justifyCenter="center"
          alignItems="felx-start"
          spacing={7}
        >
          <Box
            component="img"
            src={logo}
            alt=""
            sx={{ width: 155, height: 26, mx: "auto", mt: 4 }}
          />
          {NavItems.map((item, index) => {
            return (
              <Box onClick={() => handleMenuDisplay(item.sectionId)}>
                <EnglishNavItem>{item.english}</EnglishNavItem>
                <JapaneseNavItem>{item.japanese}</JapaneseNavItem>
              </Box>
            );
          })}
        </Stack>
      </MenuContainer>
    );
  }
}

export default NavMenu;
