import { Box, Typography } from "@mui/material";
import styled from "styled-components";

export const Container = styled(Box)`
  && {
    padding-top: 40px;
    padding-bottom: 130px;
    max-width: 1100px;
  }
`;

export const Title = styled(Typography)`
  && {
    font-family: "proxima_nova_rgregular";
    font-style: normal;
    font-weight: 600;
    letter-spacing: 0.04em;
    color: #5863f8;
  }
`;

export const SubTitle = styled(Typography)`
  && {
    font-family: "Noto Sans JP";
    font-style: normal;
    font-weight: 700;
    letter-spacing: 0.04em;
    color: #222222;
    margin-bottom: 45px;
  }
`;

export const ImageContainer = styled(Box)`
  && {
    background: #ffffff;
    box-shadow: 0px 8px 40px rgba(64, 68, 125, 0.15);
    border-radius: 20px;
  }
`;
