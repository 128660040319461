import "./App.css";
import { Box } from "@mui/material";

import Hero from "./components/herosection/hero";
import Companies from "./components/companies/carousel";
import About from "./components/aboutsection/about";
import Workflow from "./components/workflow/flow";
import Example from "./components/examplesection/example";
import Creator from "./components/creatorsection/creator";
import QAndA from "./components/q&a/q&a";
import Contact from "./components/contactsection/contact";
import Footer from "./components/footersection/footer";

function App() {
  return (
    <Box sx={{ background: "#222222" }}>
      <Hero />
      <Companies />
      <About />
      <Workflow />
      <Example />
      <Creator />
      <QAndA />
      <Contact />
      <Footer />
    </Box>
  );
}

export default App;
