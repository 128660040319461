import React, { useState } from "react";
import axios from "axios";
import { Box, Stack } from "@mui/material";
import {
  Label,
  InputField,
  Checkbox,
  Caption,
  SubmitButton,
  SubmittedText1,
  SubmittedText2,
  CheckBoxError,
  PrivacyPolicyContainer,
} from "./contact.components";

function CustomForm({ formSubmitted, setFormSubmitted }) {
  const [name, setName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [isCheckPrivacyPolicy, toggleIsCheckPrivacyPolicy] = useState(false);
  const [isChecked, toggleIsChecked] = useState(false);
  const [isSubmit, toggleIsSubmit] = useState(false);

  const submitContactForm = async (e) => {
    e.preventDefault();
    toggleIsSubmit(true);
    if (!isCheckPrivacyPolicy) {
      toggleIsChecked(true);
    }
    if (!name || !companyName || !email || !phone || !isCheckPrivacyPolicy) {
      return;
    }

    const config = {
      url: "https://api.foriio.com/api/v1/foriio_lab",
      method: "post",
      headers: { "Content-Type": "application/json" },
      data: JSON.stringify({
        name: name,
        email: email,
        company_name: companyName,
        contact_number: phone,
      }),
    };
    await axios(config)
      .then((response) => {
        setFormSubmitted(true);
      })
      .catch((error) => console.log(error));
  };

  return formSubmitted ? (
    <Box component="form" sx={{ minHeight: 420 }}>
      <SubmittedText1>資料請求をいただきありがとうございます。</SubmittedText1>
      <SubmittedText2>
        メッセージが送信されました。
        <br /> 内容を確認後、返信させていただきます。
      </SubmittedText2>
    </Box>
  ) : (
    <form autoComplete="off" onSubmit={submitContactForm}>
      <Stack direction="column" spacing={2}>
        <Box>
          <Label sx={{ mb: {xs: "20px", lg: "10px", xl: "20px"} }}>お名前<Caption txtcolor="#EB5757" fontWeight="bold" component="span">*</Caption></Label>
            <InputField value={name}
              setValue={setName}
              helperText={"この必須項目を入力してください。"}
              isSubmit={isSubmit}
            />
        </Box>
        <Box sx= {{ mt: {xs: "20px !important"} }}>
            <Label sx={{ mb: !isChecked && {xs: "20px", lg: "10px", xl: "20px"} }}>会社名<Caption txtcolor="#EB5757" fontWeight="bold" component="span">*</Caption></Label>
            <InputField
              name="companyName"
              value={companyName}
              setValue={setCompanyName}
              helperText={"この必須項目を入力してください。"}
              isSubmit={isSubmit}
            />
        </Box>
        <Box sx= {{ mt: {xs: "20px !important"} }}>
          <Label sx={{ mb: {xs: "20px", lg: "10px", xl: "20px"} }}>メールアドレス<Caption txtcolor="#EB5757" fontWeight="bold" component="span">*</Caption></Label>
            <InputField
              value={email}
              setValue={setEmail}
              helperText={"この必須項目を入力してください。"}
              isSubmit={isSubmit}
            />
        </Box>
        <Box sx= {{ mt: {xs: "20px !important"} }}>
          <Label sx={{ mb: {xs: "20px", lg: "10px", xl: "20px"} }}>電話番号<Caption txtcolor="#EB5757" fontWeight="bold" component="span">*</Caption></Label>
            <InputField
              value={phone}
              setValue={setPhone}
              helperText={"この必須項目を入力してください。"}
              isSubmit={isSubmit}
            />
        </Box>
      </Stack>
      <PrivacyPolicyContainer> 
        <Box sx={{ mt: "20px", mb: !isChecked && "20px", textAlign: "start", display: "flex" }}>
          <Checkbox
            type="checkbox"
            onChange={() => {
              toggleIsCheckPrivacyPolicy(!isCheckPrivacyPolicy)
              toggleIsChecked(false);
            }}
          />
          <Caption txtcolor="#222222">
            <Caption
              txtcolor="#5863F8"
              component="a"
              href="https://www.foriio.com/privacy-policy"
              target="_blank"
            >
            プライバシーポリシー 
            </Caption>
            を確認、同意の上送信してください。<Caption txtcolor="#EB5757" component="span">*</Caption>
          </Caption>
      </Box>
      {isChecked && <CheckBoxError sx={{ mb: "30px", mt: "5px",}}>必須項目が未選択です。</CheckBoxError >}
      </PrivacyPolicyContainer>
      <Stack direction="row" justifyContent="center">
        <SubmitButton type="submit">送信する</SubmitButton>
      </Stack>
    </form>
  );
}

export default CustomForm;
