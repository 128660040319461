import { Box, Stack } from "@mui/material";
import React from "react";
import {
  Container,
  Description,
  Heading,
  ShowMoreButton,
  SubTitle,
  Title,
} from "./example.components";
import { Examples } from "./content";

function Example() {
  return (
    <Container
      sx={{ pl: { xs: 2.5, sm: "50px", lg: 0 }, mx: "auto", pr: { xs: 2.5, sm: "50px", lg: 0 } }}
    >
      <Title sx={{ fontSize: {xs: "40px", sm:"80px" ,lg: "60px"} }}>EXAMPLE</Title>
      <SubTitle sx={{ fontSize: { xs: "20px", sm: "32px", lg: "24px" } }}>- 事例・インタビュー</SubTitle>
      <div id="example" />
      <Stack
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 5, sm: 2.5, lg: 5 }}
      >
        {Examples.map((data, index) => {
          return (
            <Box key={data.url} sx={{ width: { sm: "50%", lg: 340 } }}>
              <Box
                component="a"
                href={data.url}
                target="_blank"
                sx={{ textDecoration: "none" }}
              >
                <Box
                  component="img"
                  src={data.image}
                  alt=""
                  sx={{
                    width: "100%",
                  }}
                />
                <Heading>{data.title}</Heading>
                <Description>{data.description}</Description>
              </Box>
            </Box>
          );
        })}
      </Stack>
      <Stack
        direction="row"
        justifyContent="center"
        sx={{ mt: { xs: 7, sm: 4, lg: 7 } }}
      >
        <ShowMoreButton
          component="a"
          href="https://ownedmedia.foriio.com/category/foriio/interview/"
          target="_blank"
          sx={{ 
              height: "50px", 
              width: "230px",
              fontSize: "16px"
            }}
        >
          もっと事例を見る
        </ShowMoreButton>
      </Stack>
    </Container>
  );
}

export default Example;
