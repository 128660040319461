export const NavItems = [
  {
    japanese: "foriio Labとは？",
    english: "ABOUT",
    sectionId: "#about",
  },
  {
    japanese: "新しいワークフロー",
    english: "WORK FLOW",
    sectionId: "#workflow",
  },
  {
    japanese: "事例・インタビュー",
    english: "EXAMPLE",
    sectionId: "#example",
  },
  {
    japanese: "クリエイター実績一例",
    english: "CREATOR",
    sectionId: "#creator",
  },
  {
    japanese: "よくある質問",
    english: "Q&A",
    sectionId: "#qna",
  },
  {
    japanese: "お問い合わせ・資料請求",
    english: "CONTACT",
    sectionId: "#contact",
  },
];
