import React, { Fragment, useMemo, useState } from "react";
import { Avatar, Box, Grid, Stack } from "@mui/material";
import {
  CategoryButton,
  CategoryText,
  Container,
  ShowMoreButton,
  SubTitle,
  Title,
  ImageGradiant,
  CardGrid,
} from "./creator.components";
import Loader from '../loader';
import creatorTestImg from "../../images/creator-test.webp";
import creatorProfileImg from "../../images/creator-image.webp";
import ExampleModal from "./details/modal";
import axios from "axios";

const Categories = [
  "グラフィックデザイン",
  "Webデザイン",
  "UIデザイン",
  "イラストレーター",
  "タイトルロゴ",
  "3D",
  "映像制作",
  "ロゴデザイン",
  "キャラクターデザイン",
  "名刺デザイン",
  "ライター",
  "VTuber",
];

function Creator() {
  const [allCategory, setAllCategory] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  let [category, setCategory] = useState(null);
  let [modalDetails, setModalDetails] = useState(null);
  let [categoryIndex, setCategoryIndex] = useState(null);
  let [creators, setCreators] = useState(null);
  let [randomCreators, setRandomCreators] = useState([]);

  useMemo(() => getApiData(), []);

  function getApiData() {
    axios
      .get("https://api.foriio.com./api/v1/foriio_lab")
      .then((response) => {
        const creatorsData = response.data;
        const randomCreatorsData = [];
        Object.keys(creatorsData).map((key, index) => {
          var tempData = creatorsData[key].works[0];
          tempData["category"] = key;
          randomCreatorsData.push(tempData);
        });
        let ids = [20568, 264846, 218495, 59515]
        let categories = ['グラフィックデザイン', 'Webデザイン', '映像制作', 'ロゴデザイン']
        categories.forEach((category, idx) => {
            randomCreatorsData.push(creatorsData[category]['works'].find(work => work.id === ids[idx]))
        })
        setCreators(creatorsData);
        setRandomCreators(randomCreatorsData);
      })
      .catch((error) => {
        throw error;
      });
  }

  console.log('categoryIndex ', categoryIndex);
  if (categoryIndex === null) {
    const gg = randomCreators.map((data, index) => {
      return CreatorCard(data, index);
    });
    console.log('gg ', gg);
  } else {
    console.log('creator ', creators);
    if (!creators) {
      const aa = creators[category]?.works.map((data, index) => {
        return CreatorCard(data, index);
      });
      console.log('creators ', category, aa)
    }
  }

  const renderCreatorCards = () => {
    if (randomCreators?.length > 0) {
      // console.log('vv ', randomCreators.map((data, index) => {
      //   return CreatorCard(data, index);
      // }))
      return (
        <CardGrid>
          {!categoryIndex ? randomCreators.map((data, index) => {
            return CreatorCard(data, index);
          }) : creators[category]?.works.map((data, index) => {
            return CreatorCard(data, index);
          })}
        </CardGrid>
      )
    }
    return (
      <Loader />
    );
  }

  return (
    <Fragment>
      {modalDetails ? (
        <ExampleModal
          openModal={openModal}
          setOpenModal={setOpenModal}
          data={modalDetails}
        />
      ) : (
        ""
      )}
      <Container
        sx={{ pl: { xs: 2.5, sm: "50px", md: 6, lg: 0 }, mx: "auto", pr: { xs: 2.5, sm: "50px", md: 6, lg: 0 }, }}
      >
        <Title sx={{ fontSize: { xs: "40px", sm:"80px" ,lg: "60px" } }}>CREATOR</Title>
        <SubTitle sx={{ fontSize: { xs: "20px", sm: "32px", lg: "24px" } }}>- クリエイター実績一例</SubTitle>
        <div id="creator" />
        <Grid
          container
          justifyContent={{ xs: "space-around", lg: "center" }}
          spacing={1.2}
          sx={{ mb: { xs: 5, lg: 7 } }}
        >
          {Categories.map((selectedCategory, index) => {
            return window.screen.width <= 520 && !allCategory
              ? index < 6
                ? TagButton(selectedCategory, index)
                : ""
              : TagButton(selectedCategory, index);
          })}
        </Grid>
        {!allCategory ? (
          <Stack
            direction="row"
            justifyContent="center"
            sx={{
              mb: 7,
              display: { xs: "flex", sm: "none" },
            }}
          >
            <ShowMoreButton onClick={() => setAllCategory(true)}>
              もっと見る ＋
            </ShowMoreButton>
          </Stack>
        ) : (
          ""
        )}
        {renderCreatorCards()}
      </Container>
    </Fragment>
  );

  function TagButton(categoryText, index) {
    return (
      <Grid item key={index}>
        <CategoryButton
          sx={{
            background: categoryIndex === index ? "#5863F8" : "#F9F2FF",
            color: categoryIndex === index ? "#F9F2FF" : "#5863F8",
          }}
          onClick={() => {
            categoryIndex = index;
            setCategory(categoryText);
            setCategoryIndex(categoryIndex);
          }}
        >
          {categoryText}
        </CategoryButton>
      </Grid>
    );
  }

  function CreatorCard(data, index) {
    return (
      <Grid
        key={index}
        item
        sx={{
          position: "relative",
          cursor: "pointer",
          // width: { xs: "48.36%", sm: 174, md:'23.5%', lg:266 },
          // height: { xs: 101, sm: 110, md:130, lg: 163 },
          // width: { xs: "48%", sm: "33%", md: "24%", lg: "16%" },
          // height: { xs: 101, sm: 110, md:130, lg: 163 },
          // ml: { xs: index % 2 === 0 ? 0 : "11px", sm: index % 4 === 0 ? 0 : 1, lg: index % 4 === 0 ? 0 : 1.8 },
          // mb: { xs: "15px", sm: "12px", lg: "18px" }
        }}
        onClick={() => {
          setModalDetails(data);
          setOpenModal(true);
        }}
      >
        <ImageGradiant />
        <Box
          component="img"
          src={data.thumbnail}
          alt=""
          sx={{
            width: "100%",
            height: "100%",
            borderRadius: "5px",
            aspectRatio: "16 / 9",
          }}
        />
        <Stack
          direction="row"
          alignItems="center"
          spacing={1.75}
          sx={{
            position: "absolute",
            bottom: {xs: "3px", md: "5px"},
            left: { xs: "9px", md: "14px" },
          }}
        >
          <Avatar
            src={data.created_by.thumbnails.thumb}
            alt=""
            sx={{
              width: { xs: 22, md: 27, lg: 37 },
              height: { xs: 22, md: 27, lg: 37 },
            }}
          />
          <CategoryText sx={{ fontSize: { xs: "10px", lg: "14px" } }}>
            {/* グラフィックデザイン */}
            {data.credits[0]?.creative_roles[0]?.name}
          </CategoryText>
        </Stack>
      </Grid>
    );
  }
}

export default Creator;
