import cardImage1 from "../../images/card-1-img.webp";
import cardImage2 from "../../images/card-2-img.webp";

export const CardContent = [
  {
    tag: "Why foriio?",
    title: "foriioを選ぶ理由",
    points: [
      "58,000人以上の優秀なクリエイター",
      "290,000件超のポートフォリオ",
      "Web・動画・漫画etc...様々な対応が可能",
      "実績がある上でクリエイターをアサイン可",
    ],
    image: cardImage1,
  },
  {
    tag: "Features foriio Lab",
    title: "foriio Labの特徴",
    points: [
      "固定チームで回を増すたび効率アップ",
      "グロースハッカーが高速改善実施",
      "プロの専任ディレクターが仕組み作り",
      "ご担当者様と月1回の調整ミーティング",
    ],
    image: cardImage2,
  },
];
