import { Box, Typography } from "@mui/material";
import styled from "styled-components";

export const MenuText = styled(Typography)`
  && {
    font-family: proxima_nova_rgregular;
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 0px;
    letter-spacing: 0.04em;
    margin-right: 5px;
  }
`;

export const MenuContainer = styled(Box)`
  && {
    width: 100vw;
    height: 100vh;
    background: linear-gradient(91.6deg, #5863f8 2.02%, #9437f2 97.29%);
    position: fixed;
    left: 0;
    top: 0;
  }
`;

export const EnglishNavItem = styled(Typography)`
  && {
    font-family: proxima_nova_rgregular;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    letter-spacing: 0.04em;
    color: #ffffff;
    text-align: center;
  }
`;

export const JapaneseNavItem = styled(Typography)`
  && {
    font-family: Noto Sans JP;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    letter-spacing: 0.05em;
    color: #ffffff;
    text-align: center;
  }
`;
