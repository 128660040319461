import { ButtonBase } from "@mui/material";
import styled from "styled-components";

export const NavItem = styled(ButtonBase)`
  && {
    font-family: "Noto Sans JP";
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 0%;
    color: #ffffff;
    margin-left: 30px;
  }
`;

export const SolidButton = styled(ButtonBase)`
  && {
    width: 250px;
    height: 50px;
    background: linear-gradient(91.6deg, #5863f8 2.02%, #9437f2 97.29%);
    box-shadow: 2px 3px 30px rgba(88, 99, 248, 0.5);
    border-radius: 100px !important;

    font-family: "Noto Sans JP";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 0%;
    letter-spacing: 0.05em;
    color: #ffffff;
  }
`;
