import React from "react";
import { Box, ButtonBase, TextField, Typography } from "@mui/material";
import styled from "styled-components";
import { useState } from "react";
export const Container = styled(Box)`
  && {
    padding-bottom: 130px;
    max-width: 1100px;
  }
`;

export const Title = styled(Typography)`
  && {
    font-family: "proxima_nova_rgregular";
    font-style: normal;
    font-weight: 600;
    letter-spacing: 0.04em;
    color: #5863f8;
  }
`;

export const PrivacyPolicyContainer = styled(Typography)`
  && {
    margin: auto;
    width: fit-content;
  }
`;

export const FormTitle = styled(Box)`
  && {
    height: 45px;
    font-family: "Noto Sans JP";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    letter-spacing: 0.07em;
    color: #222222;
    text-align: center;
    margin-top: 10px;
  }
`;

export const TitleLine = styled(Box)`
  && {
    height: 1.25px;
    width: 60px;
    background: linear-gradient(91.6deg, #5863f8 2.02%, #9437f2 97.29%);
    margin: auto;
    margin-bottom: 10px;
  }
`;

export const SubTitle = styled(Typography)`
  && {
    font-family: "Noto Sans JP";
    font-style: normal;
    font-weight: 700;
    letter-spacing: 0.04em;
    color: #ffffff;
    margin-bottom: 60px;
  }
`;

export const Card = styled(Box)`
  && {
    background: #ffffff;
    height: 100%;
    min-height: 775px;
    margin: auto;
  }
`;

export const Heading = styled(Typography)`
  && {
    font-family: "Noto Sans JP";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    letter-spacing: 0.05em;
    color: #222222;
    max-width: 428px;
    margin-left: auto;
    margin-right: auto;
  }
`;

export const Tab = styled(ButtonBase)`
  && {
    width: 140px;
    height: 32px;
    text-align: center;
    border-bottom: 1px solid;

    font-family: "Noto Sans JP";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
  }
`;

export const Label = styled(Typography)`
  && {
    font-family: "Noto Sans JP";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    color: #222222;
  }
`;

export const Checkbox = styled.input`
  position: relative;
  top: 2px;
  margin: 3px 3px auto 0;
`;

export const Caption = styled(Typography)`
  && {
    display: inline-block;
    font-family: "Noto Sans JP";
    font-style: normal;
    font-weight: ${(props) => props.fontWeight || 400};
    font-size: 14px;
    color: ${(props) => props.txtcolor};
    text-decoration: none;
  }
`;

export const SubmitButton = styled(ButtonBase)`
  && {
    width: 280px;
    height: 50px;
    background: linear-gradient(91.6deg, #5863f8 2.02%, #9437f2 97.29%);
    box-shadow: 2px 3px 30px rgba(88, 99, 248, 0.5);
    border-radius: 100px;

    font-family: "Noto Sans JP";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    text-align: center;
    letter-spacing: 0.05em;
    color: #ffffff;
  }
`;
export const CheckBoxError = styled(Typography)`
 && {
  color: #d32f2f;
  text-align: start;
  font-family: "Roboto","Helvetica","Arial",sans-serif;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.66;
 }
`
export const SubmittedText1 = styled(Typography)`
  && {
    font-family: "Noto Sans JP";
    font-style: normal;
    font-weight: 700;
    font-size: 17px;
    line-height: 28px;
    margin-top: 35px;

    background: linear-gradient(91.6deg, #5863f8 2.02%, #9437f2 97.29%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }
`;

export const SubmittedText2 = styled(Typography)`
  && {
    width: 265px;
    font-family: "Noto Sans JP";
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 28px;
    color: #222222;
    margin-top: 30px;
  }
`;

export const InputTextField = styled(TextField)`
  min-width: 100% !important;
  .MuiOutlinedInput-root {
    height: 40px;
    padding: 0px 5px;
    border-radius: 8px;
    & > fieldset {
      border-color: #828282;
    }
  }
  & > label.Mui-focused {
    color: #261818;
  }
  & > .MuiOutlinedInput-root {
    &.Mui-focused fieldset {
      border-color: #5863f8 !important;
    }
  }
`;

export const InputField = (props) => {
  const [isBlur, setIsBlur] = useState(false);
  return (
    <InputTextField
      onChange={(e) => props.setValue(e.target.value)}
      variant="outlined"
      value={props.value}
      type={props.type || "text"}
      sx={{
        width: {
          xs: "100%",
          "& .MuiOutlinedInput-root:hover": {
            "& > fieldset": { borderColor: "#828282" },
          },
        },
      }}
      error={props.value === '' && (isBlur || props.isSubmit) }
      helperText={ props.value === '' && (isBlur || props.isSubmit) ?  props.helperText : ''}
      onBlur={() => setIsBlur(true)}
    />
  );
};
